export const tr = {
  siteTitle: "COVID19Daily.info",
  description:
    "Günlük güncellenen COVID-19 salgını istatistikleri, ülke bazlı COVID-19 rakamlarını görüntüleyin",
  totalCases: "Toplam Teşhisler",
  totalActiveCases: "Toplam Aktif Teşhis Sayısı",
  totalRecoveredCases: "Toplam İyileşen Sayısı",
  totalDeaths: "Toplam Ölüm Sayısı",
  totalCaseDistribution: "Toplam Teşhis Dağılımı",
  active: "Aktif Teşhisler",
  language: "Dil",
  recovered: "İyileşenler",
  deaths: "Ölümler",
  statsByCountry: "Ülke Bazlı COVID-19 İstatistikleri",
  searchPlaceholder: "ülkelerde ara",
  clear: "Temizle",
  country: "Ülke",
  diagnoses: "Teşhisler",
  new: "Yeni",
  total: "Toplam",
  singleCountryTitle: `İlk Teşhisten İtibaren Günlük COVID-19 İstatistikleri`,
  dailySpread: "Günlük Salgın Yayılımı",
  dailyRecoveries: "Günlük İyileşenler",
  dailyDeaths: "Günlük Ölümler",
  affectedPeople: "Etkilenen kişi sayısı",
  totalDiagnoses: "Toplam Teşhisler",
  compareTo: "Şu ülkeyle karşılaştır:",
  select: "Ülke seçiniz",
  today: "bugün",
};
