export const en = {
  siteTitle: "COVID19Daily.info",
  description:
    "Daily updated COVID-19 pandemic statistics, browse country by country COVID-19 numbers",
  language: "Language",
  totalCases: "Total Cases",
  totalActiveCases: "Total Active Cases",
  totalRecoveredCases: "Total Recovered Cases",
  totalDeaths: "Total Deaths",
  totalCaseDistribution: "Total Case Distribution",
  active: "Active",
  recovered: "Recovered",
  deaths: "Deaths",
  statsByCountry: "COVID-19 Statistics by Country",
  searchPlaceholder: "search in countries",
  clear: "Clear",
  country: "Country",
  diagnoses: "Diagnoses",
  new: "New",
  total: "Total",
  singleCountryTitle: `Daily COVID-19 Statistics Since First Diagnosis`,
  dailySpread: "Daily Spread",
  dailyRecoveries: "Daily Recoveries",
  dailyDeaths: "Daily Deaths",
  affectedPeople: "Affected People",
  totalDiagnoses: "Total Diagnoses",
  compareTo: "Compare To:",
  select: "Select a country",
  today: "today",
};
